<template>
    <v-container fulid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-row dense class="py-1">
                    <v-col cols="12">
                        <v-card :elevation="1">
                            <v-card-text class="py-1">
                                <v-row dense>
                                    <v-col md="3">
                                        <v-combobox label="Department" dense outlined hide-details clearable
                                            :items="$store.getters['department/departments']"
                                            v-model="selectedDepartment" item-text="name" item-value="id"
                                            :loading="$store.getters['department/loadingDepartments']"
                                            @focus="$store.dispatch('department/getDepartments')"
                                            class="custom-form"></v-combobox>
                                    </v-col>
                                    <v-col md="3">
                                        <v-combobox label="Designation" dense outlined hide-details clearable
                                            :items="$store.getters['designation/designations']"
                                            :loading="$store.getters['designation/loadingDesignations']"
                                            @focus="$store.dispatch('designation/getDesignations')"
                                            v-model="selectedDesignation" item-text="name" item-value="id"
                                            class="custom-form"></v-combobox>
                                    </v-col>
                                    <v-col md="3">
                                        <v-combobox label="Employee" dense outlined hide-details clearable
                                            v-model="selectedEmployee" :items="$store.getters['employee/employees']"
                                            :loading="$store.getters['employee/loadingEmployees']"
                                            @focus="$store.dispatch('employee/getEmployees')" item-text="display_name"
                                            item-value="id" class="mb-1 custom-form"></v-combobox>
                                    </v-col>
                                    <v-col md="3">
                                        <v-btn small color="info" height="25" :elevation="1"
                                            @click="getEmployees">Search</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters class="pt-1" justify="center" v-if="employees.length > 0">
                    <v-col cols="6">
                        <v-btn color="blue-grey lighten-4" @click.prevent="print" height="25"
                            :elevation="1">Print</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" id="invoiceContent" class="pb-2">
                <v-row dense style="justify-content: center;" v-if="employees.length > 0">
                    <v-col cols="6">
                        <v-row no-gutters v-for="(employee, ind) in employees" :key="ind">
                            <v-col cols="6">
                                <div class="card">
                                    <div class="card-body" style="padding: 3px 10px 10px;">
                                        <div>
                                            <div style="display: flex;align-items: center;justify-content: center;">
                                                <img style="width: 40px; height: 40px;"
                                                    :src="`${$store.state.host + company_profile.logo}`">
                                                <p
                                                    style="margin: 0px; font-weight: 900; font-size: 20px; word-spacing: 10px;">
                                                    {{ company_profile.name }}</p>
                                            </div>
                                            <p
                                                style="margin: 0 auto; font-weight: 700; font-size: 15px; text-align: center;">
                                                কার্ড নং- <strong>{{ employee.emp_id }}</strong>
                                            </p>
                                            <div style="text-align: center;">
                                                <img class="card-img"
                                                    :src="`${$store.state.host}${employee.image ? employee.image : '/no-image.png'}`"
                                                    alt="">
                                            </div>
                                            <p
                                                style="text-align:center;text-transform: uppercase;font-size:15 !important;line-height: 15px !important;margin-bottom: 0;">
                                                <strong>{{ employee.name }}</strong>
                                            </p>
                                            <div>
                                                <table style="width: 100%;">
                                                    <tr>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>পদবী</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>:</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>{{ employee.designation_name }}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>বিভাগ</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>:</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>{{ employee.department_name }}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>লাইন</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>:</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong></strong>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table style="width: 100%;">
                                                    <tr>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>যোগদানের
                                                                তারিখ</strong></td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>:</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>{{ employee.joining_date |
                                                                dateFormat('DD/MM/YYYY') }}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>ইস্যুর
                                                                তারিখ</strong></td>
                                                        <td style="font-size: 12px;padding: 0px;"><strong>:</strong>
                                                        </td>
                                                        <td style="font-size: 12px;padding: 0px;">
                                                            <strong>{{ employee.created_at | dateFormat('DD/MM/YYYY')
                                                                }}</strong>
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div
                                                    style="display: flex;align-items: center;justify-content: space-between;margin-top: 1px;">
                                                    <p style="margin-bottom: 0;text-decoration: overline;width: 65%;">
                                                    </p>
                                                    <p style="margin-bottom: 0;width: 35%;">
                                                        <img style="width: 100%; height: 25px;"
                                                            :src="`${$store.state.host + '/signature.png'}`">
                                                    </p>
                                                </div>
                                                <div
                                                    style="display: flex;align-items: center;justify-content: space-between;">
                                                    <p
                                                        style="margin-bottom: 0;text-decoration: overline;font-size: 12px;">
                                                        কর্মচারী/কর্মকর্তা</p>
                                                    <p
                                                        style="margin-bottom: 0;text-decoration: overline;font-size: 12px;">
                                                        কর্তৃপক্ষ</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div class="card">
                                    <div class="card-body" style="padding: 3px 10px 10px;">
                                        <div>
                                            <p
                                                style="text-align: center; font-size: 8px; font-weight: 700; margin-bottom: 5px; border-bottom: 1px solid gray;">
                                                যদি আইডি কার্ডটি হারানো যায় তাহলে নিম্ন ঠিকানায় ফেরত প্রদানের জন্য
                                                অনুরোধ করা হলো।
                                            </p>
                                            <div style="text-align: center;">
                                                <img style="width: 40px; height: 40px; border: 1px solid black;"
                                                    :src="`${$store.state.host + company_profile.logo}`">
                                            </div>
                                            <div style="text-align: center">
                                                <p
                                                    style="margin: 0px; font-weight: 900; font-size: 16px; word-spacing: 6px;border-bottom: 3px solid black;display: inline-block;">
                                                    {{ company_profile.name }}</p>
                                            </div>
                                            <div style="text-align: center;">
                                                <p
                                                    style="margin: 0px; font-size: 14px;border-bottom: 1px solid black;display: inline-block;">
                                                    {{ company_profile.address }}</p>
                                            </div>
                                            <div style="border-bottom: 1px solid black;">
                                                <p style="margin: 0px; font-size: 11px;"><strong>ই-মেইল: </strong>
                                                    {{ company_profile.email }}</p>
                                                <p style="margin: 0px; font-size: 11px;"><strong>ওয়েব: </strong>
                                                    www.funfactorybd.com</p>
                                                <p style="margin: 0px; font-size: 11px;"><strong>জরুরী ফোন নং: </strong>
                                                    {{ company_profile.phone }}</p>
                                            </div>
                                            <div style="border-bottom: 1px solid black;text-align: center;">
                                                <p style="margin: 0px; font-size: 14px;"><strong>শ্রমিকের স্থায়ী ঠিকানাঃ
                                                    </strong></p>
                                            </div>
                                            <div style="border-bottom: 1px solid black;text-align: center;">
                                                <p style="margin: 0px; font-size: 11px;">{{ employee.present_address }},
                                                    {{ employee.area_name }}</p>
                                            </div>
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>রক্তের গ্রুপ</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px"><strong>:</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>{{ employee.blood_group }}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>জাতীয় পরিচয় পত্র</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px"><strong>:</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>{{ employee.nid }}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>জন্ম তারিখ</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px"><strong>:</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>{{ employee.birth_date | dateFormat("DD/MM/YYYY") }}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>জরুরী ফোন নং</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px"><strong>:</strong>
                                                    </td>
                                                    <td style="font-size: 11px;padding: 0px">
                                                        <strong>{{ employee.emergency_contact_phone }}</strong>
                                                    </td>
                                                </tr>
                                            </table>

                                            <div style="text-align: center;margin-top:10px;">
                                                <p style="margin: 0px;font-size: 8px;font-weight: 900;line-height: 1;">উক্ত পরিচয়পত্র হারাইয়া গেলে তাৎক্ষনিক কতৃপক্ষকে জানাইতে হবে।</p>
                                            </div>
                                            <div style="display: flex; flex-direction: column; align-items: center;">
                                                <div style="width: 90%; height: 3px; background: black; margin-top: 7px;"></div>
                                                <div style="width: 80%; height: 3px; background: black; margin-top: 7px;"></div>
                                                <div style="width: 70%; height: 3px; background: black; margin-top: 7px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment"
export default {
    data: () => ({
        selectedDepartment: null,
        selectedEmployee: null,
        selectedDesignation: null,
        employees: [],
        company_profile: "",
    }),

    filters: {
        dateFormat(dt, format) {
            return dt == "" || dt == null ? "" : moment(dt).format(format);
        }
    },

    async created() {
        this.company_profile = this.$store.getters['company/company'];
        this.setStyle();
    },

    methods: {
        getEmployees() {
            let filter = {
                id: this.selectedEmployee?.id,
                departmentId: this.selectedDepartment?.id,
                designationId: this.selectedDesignation?.id,
                with_relations: true,
            }
            axios.post('/get_employees', filter)
                .then(res => {
                    this.employees = res.data;
                })
        },

        setStyle() {
            this.style = document.createElement('style');
            this.style.innerHTML = `
                @media print {
                    .row {
                        --bs-gutter-x: 1.5rem;
                        --bs-gutter-y: 0;
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .col-md-auto {
                        flex: 0 0 auto;
                        width: auto;
                    }
                    .col-md-1 {
                        flex: 0 0 auto;
                        width: 8.33333333%;
                    }
                    .col-md-2 {
                        flex: 0 0 auto;
                        width: 16.66666667%;
                    }
                    .col-md-3 {
                        flex: 0 0 auto;
                        width: 25%;
                    }
                    .col-md-4 {
                        flex: 0 0 auto;
                        // width: 33.33333333%;
                        width: 50%;
                    }
                    .col-md-5 {
                        flex: 0 0 auto;
                        width: 41.66666667%;
                    }
                    .col-md-6 {
                        flex: 0 0 auto;
                        width: 50%;
                    }
                    .col-md-7 {
                        flex: 0 0 auto;
                        width: 58.33333333%;
                    }
                    .col-md-8 {
                        flex: 0 0 auto;
                        width: 66.66666667%;
                    }
                    .col-md-9 {
                        flex: 0 0 auto;
                        width: 75%;
                    }
                    .col-md-10 {
                        flex: 0 0 auto;
                        width: 83.33333333%;
                    }
                    .col-md-11 {
                        flex: 0 0 auto;
                        width: 91.66666667%;
                    }
                    .col-md-12 {
                        flex: 0 0 auto;
                        width: 100%;
                    }
                    .hide {
                        display: none
                    }
                    .text-school {
                        font-size: 1.2rem !important;
                        font-weight: 500;
                        letter-spacing: 0.5px !important;
                        font-family: "Roboto", sans-serif !important;
                        text-align: center !important;
                    }
                    .StuIdCard {
                        margin: 5px !important;
                        padding: 15px !important;
                        border: 1px solid #ccc !important;
                        height:210px;
                    }
                    .IdCardData {
                        padding-top: 15px;
                        font-size: 18px !important;
                    }
                    .cardImg {
                        height: 100px; 
                        min-width: 100px; 
                        width: 100px;
                    }
                    .card{
                        border-radius: 8px;
                        width: 250px;
                        height: 400px;
                        margin: 20px auto;
                        transition: 0.3s;
                        padding: 5px ;
                        border: 1px solid #ccc;
                        font-family: sans-serif;
                        margin-left: 5px;
                    }
                    .head4{
                        background: darkgreen;
                        margin: 4px 60px;
                        padding: 5px 5px;
                        text-align: center;
                        border-radius: 20px;
                        color: #ffffff;
                    }
                    .head5{
                        background: #df1500;
                        margin: 4px 60px;
                        padding: 5px 5px;
                        text-align: center;
                        border-radius: 20px;
                        color: #ffffff;
                    }
                    .card-img{
                        width: 130px;
                        border: 2px solid #000;
                        border-radius: 15px;
                        height: 125px;
                    }
                    .sig-img{
                        width: 33%;
                        height: 40px;
                    }
                    .head{
                        margin: 1px auto;
                    }
                    .rule-text {
                        font-family: sans-serif;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 2px;
                    }
                    .p-info {
                        color: darkgreen;
                        margin-top: 20px;
                        font-weight: 500; 
                        font-size: 11px;
                        line-height: 11px !important;
                    }
                    .p-info p {
                        margin-bottom: 5px !important;
                    }
                    .p-info .addres {
                        line-height: 17px;
                    }
                    .c-info {
                        color: #002795; 
                        margin-top: 28px; 
                        text-align: center;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 3px;
                    }
                    .st-info {
                        text-align: center;
                        line-height: 5px;
                        font-size: 15px;
                        padding-top: 12px;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },

        async print() {
            let reportContent = `
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								${document.querySelector('#invoiceContent').innerHTML}
							</v-col>
						</v-row>
					</v-container>
				`;

            var reportWindow = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);

            reportWindow.document.body.innerHTML += reportContent;

            let invoiceStyle = reportWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            reportWindow.document.head.appendChild(invoiceStyle);

            reportWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            reportWindow.print();
            reportWindow.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.text-school {
    font-weight: 500;
    letter-spacing: 0.5px !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 15px !important;
}

.v-card__title {
    padding: 13px 16px 0px 16px;
    line-height: 1rem !important;
    justify-content: center;
}

.cus-title {
    padding-top: 4px !important;
}

.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle,
.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__action-text {
    color: #000;
}

.card {
    border-radius: 8px;
    width: 250px;
    height: 400px;
    margin: 20px auto;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 5px;
    font-family: sans-serif;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.head4 {
    background: darkgreen;
    margin: 4px 60px;
    padding: 5px 5px;
    text-align: center;
    border-radius: 20px;
    color: #ffffff;
}

.head5 {
    background: #df1500;
    margin: 4px 60px;
    padding: 5px 5px;
    text-align: center;
    border-radius: 20px;
    color: #ffffff;
}

.card-img {
    width: 130px;
    border: 2px solid #000;
    border-radius: 15px;
    height: 125px;
}

.sig-img {
    width: 33%;
    height: 40px;
}

.head {
    margin: 1px auto;
}

.rule-text {
    font-family: sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 2px;
}

.p-info {
    color: darkgreen;
    margin-top: 20px;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
}

.p-info p {
    margin-bottom: 5px;
}

.p-info .addres {
    line-height: 17px;
}

.c-info {
    color: #002795;
    margin-top: 28px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 5px;
}

.st-info {
    text-align: left;
    line-height: 5px;
    font-size: 15px;
    padding-top: 5px;
}
</style>